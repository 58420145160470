import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Nav, Container, Row, Col } from "react-bootstrap";
import GenerateLink from "../common/site/generate-link"
// Images
import BrandLogo from "../../images/logo.svg";
import BrandLogoDark from "../../images/logo-dark.svg";
import "./Header.scss";
import MegaMenu from './MegaMenu';
import MenuData from './MenuData';
import Helmet from 'react-helmet'
import { setUtmCookie } from '../common/site/cookie';

const Header = (props) => {


  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      menusHeaders(sort: "Sort:asc") {
        id
        Label
        Link {
          id
        }
        Sort
        Submenu_Search_Title
        In_Submenu_Show_Search
        Add_Sub_Menus {
          Title
          Add_here {
            Submenu_Label
            Submenu_Link {
              id
            }
          }
        }
      }

    }
  }
`);

  const menus = data.glstrapi.menusHeaders;

  const [show, setShow] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [state, setState] = useState([]);


  useEffect(() => {
    setUtmCookie();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 1);
    });
    if (menus && menus.length > 0) {
      var mapMenuList = []
      menus.map((item, i) => {
        if (item.Add_Sub_Menus.length > 0) {
          var menu = {
            name: item.Label,
            link: item.Link,
            isActive: true,
            submenu: []
          }
          item.Add_Sub_Menus.map((submenu, i) => {
            submenu.Add_here.map((innermenu, i) => {
              menu.submenu.push({
                name: innermenu.Submenu_Label,
                link: innermenu.Submenu_Link,
                isActive: true,
              })
            })
          })
          mapMenuList.push(menu)
        } else {
          mapMenuList.push({
            name: item.Label,
            link: item.Link,
            isActive: true,
          })
        }
      })
      setState(mapMenuList)
    }
  }, []);

  const showMenu = () => {
    setShow(!show);
    document.querySelector("body").classList.add("overflow-hidden");

  };
  const handlerOpenMenu = (index) => {
    let newState = [...state];
    let a = newState.map((item, i) => {
      if (index !== i) {
        return { ...item, isActive: false };
      } else {
        return item;
      }
    });
    let isActive = a[index].isActive;
    a[index].isActive = !isActive;
    setState(a);
  };

  const handlerOpenSubMenu = (e, index, i) => {
    e.stopPropagation();
    let newState = [...state];
    let a = newState[index].submenu.map((item, j) => {
      if (i !== j) {
        return { ...item, isActive: false };
      } else {
        return item;
      }
    });
    newState[index].submenu = a;
    let isActive = newState[index].submenu[i].isActive;
    newState[index].submenu[i].isActive = !isActive;
    setState(newState);
  };

  const closeMenu = () => {
    setShow(!show);
    document.querySelector("body").classList.remove("overflow-hidden");
    setState(
      state.map(menu => {
        if (Array.isArray(menu.submenu)) {
          let p = menu.submenu.map(sub => ({ ...sub, ...{ isActive: false } }));
          return ({ ...menu, ...{ submenu: p }, ...{ isActive: false } })
        } else {
          return ({ ...menu, ...{ isActive: false } })
        }
      })
    )
  }

  return (
    <>
      <header className={scroll && props.type != "results" ? "header--fixed header" : scroll && props.type == "results" ? "header results-header" : "header"}>
        <Container fluid>
          <Row>
            <Col>
              <Nav>
                <div className="header-wrapper">
                  <div className="logo">
                    {/* <Link to="/">
                      <img src={BrandLogo} alt="logo" width="108" className="light-logo" />
                      <img src={BrandLogoDark} className="dark-logo" alt="logo" width="108" />
                    </Link> */}
                  </div> 


                  <div className="nav-right ml-auto d-flex d-lg-block">
                    {/* Main Nav */}
                    <Nav className="d-none d-lg-block main-nav">
                      <ul>
                         <li><Link to="/">Home</Link></li>
                         
                        {menus &&
                          menus.map((item, i) => (
                            <li className={`${item.Add_Sub_Menus.length > 0 ? "dropdown-item-link" : ''}`} key={i}>
                              <GenerateLink link={item.Link}>{item.Label}</GenerateLink>
                              {item.Add_Sub_Menus &&
                                <MegaMenu data={item} />
                              }
                            </li>
                          )
                          )}
                      </ul>
                    </Nav>
                    <div className="menu-wrap d-lg-none">
                      <button className="hamburger" onClick={showMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                      </button>
                    </div>

                  </div>

                </div>
                {/* Navigation */}
                <div
                  className={
                    show
                      ? " navigation-wrap active justify-content-center"
                      : " navigation-wrap justify-content-center"
                  }
                >
                  <div className="navigation-title">
                    <span>menu</span>
                    <Link to="#" className="close-btn" onClick={closeMenu}>
                      <span>close</span> <i className="icon-close"></i>
                    </Link>
                  </div>

                  <Nav>
                    <div className="navigation">
                      <div className="menu-accordian">
                        <ul>
                          {state.map((item, i) => (
                            <MenuData
                              key={i}
                              index={i}
                              handlerOpenMenu={handlerOpenMenu}
                              handlerOpenSubMenu={handlerOpenSubMenu}
                              item={item}
                            />
                          ))}
                        </ul>
                      </div>
                    </div>

                  </Nav>

                </div>
              </Nav>
            </Col>
          </Row>
        </Container>

        {/* <div className="navigation-block">
          <nav className="nav-menu">
            <ul>
              <li>
                <Link to="#">Property Search</Link>
              </li>
              <li>
                <Link to="#">Valuations</Link>
              </li>
              <li>
                <Link to="#">Services</Link>
              </li>
              <li>
                <Link to="#">Insights</Link>
              </li>
              <li>
                <Link to="#">About</Link>
              </li>
              <li>
                <Link to="#">Contact</Link>
              </li>
            </ul>
          </nav>
        </div> */}
      </header>
    </>
  );
};

export default Header;
